function AboutUs(){
    return (
        <div className="content_middle_inner">
            <div className="content_middle_in">
                <div className="title">
                    <h3>About Us</h3>
                </div>
                <p>SupremeCare Corporation is a long term care consulting and management firm committed to providing exceptional services to meet the needs of our partners in care.</p>
                <p>Our approach to every situation is delivered in a manner that upholds the honesty, integrity and reputation of the clients we serve.</p>
                <h1>Why Choose Us?</h1>
                <p>SupremeCare provides services to 1400+ healthcare &nbsp;communities nationwide.&nbsp;Our clients consist of nursing homes, assisted living facilities, CCRC &#39;s,&nbsp;hospitals, renal clinics, correctional institutions and the general public.&nbsp;Our specialties are in the areas of dietary operations, management assistance, and cost control.</p>
                <p>Our services range from consultant visits to complete management programs.&nbsp;Our team of consultants prides themselves on our ability to establish rapport with your staff and to be considered part of your team.&nbsp;</p>
                <h1>SupremeCare Health Services Founder</h1>
                <p>Ellington Camp Neel has extensive experience in the long term care industry.&nbsp;He holds a Doctorate Degree in Nutrition and a Nursing Home Administrator &rsquo;s license.&nbsp;Dr. Neel has numerous credits in professional publications and speaks nationally.</p>
                <p>His experience as Chief Operating Officer at IPC of GA and Executive Director for PHGA has tremendously influenced the practicality of SCC operations.&nbsp;Over the years, Dr. Neel has developed valuable relationships with industry professionals as well as State and Federal surveyors.&nbsp;He is a member of numerous organizations and currently &nbsp;serves on University of Georgia, Raymond James and GPO advisory boards.</p>
                <h1>Our Staff</h1>
                <p>Our administrative and clinical staff consists of Licensed Administrators, Registered Dietitians, Accountants, Certified Dietary Managers, and Therapists.&nbsp;Many of our professionals have additional credentials and certifications specializing in Diabetic Education, Renal Nutrition, Geriatric Nutrition, USDA Inspection, etc.</p>
                <p>SupremeCare staff cover many states and are acutely attuned to surveyor trends and regulatory changes.&nbsp;Regional Directors are placed in each state to provide &ldquo;hands-on &rdquo;service and coordination for our clients.&nbsp;Our Dietary Management Consultants work closely with your dietary manager to train and/or provide advice in the area of purchasing, production, quality, tray appearance, sanitation, etc.&nbsp;Our Registered Dietitians focus on the clinical needs of your residents focusing on weight loss, feedings, supplement management, wound care, etc.&nbsp;The wealth of knowledge our staff possesses has proven to be invaluable in the myriad of services SupremeCare Corporation provides.&nbsp;</p>
            </div>
        </div>
    )
}

export default AboutUs;