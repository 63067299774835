import { Image } from "react-bootstrap";

import pik_man from '../../assets/images/pik_man.jpg'
import tit_1 from '../../assets/images/tit_1.jpg'
import tit_2 from '../../assets/images/tit_2.jpg'
import tit_3 from '../../assets/images/tit_3.jpg'
import tit_4 from '../../assets/images/tit_4.jpg'
import icon_1 from '../../assets/images/icon_1.jpg'
import icon_2 from '../../assets/images/icon_2.jpg'
import icon_3 from '../../assets/images/icon_3.jpg'
import icon_request from '../../assets/images/icon_request.jpg'
import icon_request_over from '../../assets/images/icon_request_over.jpg'


function Home() {
  return (
    <div className="content_middle">
      <div className="content_middle_in">
        <Image alt="test" src={pik_man} className="pik_man" />
        <div className="welcome">
          <Image alt="" src={tit_1} />
            <p>SupremeCare provides&nbsp;senior care&nbsp;nutritional consulting, dining services management consulting and web based operational software servicing 1400+ healthcare communities. We currently operate in 46 states. Click here for more information</p>		
        </div>
        <Image src={icon_request} className="request"
                onMouseOver={e => e.currentTarget.src = icon_request_over} 
                onMouseOut={e => e.currentTarget.src = icon_request}/>
      </div>
      <div className="content">
        <div className="home_box_rep">
          <div className="home_box_bot">
            <div className="home_box_top">
              <Image alt="" src={icon_1} className="icon" />
              <Image alt="" src={tit_2} />
              <p>Our management systems assist clients in controlling food and labor costs. We implement systems to assure quality and reduce expenses while focusing on positive outcomes for our residents.</p>				
            </div>
          </div>
        </div>
        <div className="home_box_rep">
          <div className="home_box_bot">
            <div className="home_box_top">
              <Image alt="" src={icon_2} className="icon" />
              <Image alt="" src={tit_3} />
              <p>SupremeCare provides Registered Dietitian Consulting and Dietary Management Consulting. These services meet the needs of our clients from the foundation up.... production of meals to nutritional life support.</p>				
            </div>
          </div>
        </div>
        <div className="home_box_rep backgrond_n">
          <div className="home_box_bot backgrond_n">
            <div className="home_box_top backgrond_n">
              <Image alt="" src={icon_3} className="icon" />
              <Image alt="" src={tit_4} />
              <p>SupremeCare designed and offers the OptimaSolutions Web Based Dining-Menu Management software. It is a complete dining services operational software that meets all your food service department&#39;s&#39;s day to day demands. Please visit our web site for additional information:&nbsp; www.optimasolutions.info&nbsp;&nbsp;</p>				
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;