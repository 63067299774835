import React from 'react';
import { Image } from "react-bootstrap";
import comp from '../../assets/images/comp.gif'

class OnlineCounselForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {value: ''};
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        this.setState({value: event.target.value});
      }
    
      handleSubmit(event) {
        alert('A name was submitted: ' + this.state.value);
        event.preventDefault();
      }


      render(){
        return(
            <form action="" method="post" id="contactForm" name="contactForm">
            <input type="hidden" name="action" value="contact" style={{display:"none"}}/>
                <fieldset>
                    <label htmlFor="name">*Name:</label>
                    <input type="text" name="name" id="name" tabIndex="1" defaultValue="" onChange={this.handleChange}/>
                </fieldset>
                <fieldset>
                    <label htmlFor="email1">*Email:</label>
                    <input type="text" name="email1" id="email1" tabIndex="2" defaultValue="" onChange={this.handleChange}/>
                </fieldset>
                <fieldset>
                    <label htmlFor="email2">*Email (confirm):</label>
                    <input type="text" name="email2" id="email2" tabIndex="3" defaultValue="" onChange={this.handleChange}/>
                </fieldset>
                <fieldset>
                    <label htmlFor="phone">*Phone (no dashes):</label>
                    <input type="text" name="phone" id="phone" tabIndex="3" defaultValue="" onChange={this.handleChange}/>
                </fieldset>
                <fieldset>
                    <label htmlFor="contact">Contact Me Via:</label>
                    <select name="contact" size="1" id="contact">
                        <option value="phone">Phone</option>
                        <option value="email">Email</option>
                    </select>
                </fieldset>
                <fieldset>
                    <label htmlFor="area">*Area of Interest: </label>
                    <div className="area">
                        <p>
                            <input name="interest" type="radio" className="check" value="LTC Placement"/>LTC Placement
                        </p>
                        <p>
                            <input name="interest" type="radio" className="check" value="Dietary Consulting"/>Dietary Consulting
                        </p>
                        <p>
                            <input name="interest" type="radio" className="check" value="Nurse Consulting"/>Nurse Consulting
                        </p>
                        <p>
                            <input name="interest" type="radio" className="check" value="Pharmacy Consulting"/>Pharmacy Consulting
                        </p>
                        <p>
                            <input name="interest" type="radio" className="check" value="Purchasing"/>Purchasing
                        </p>
                        <p>
                            <input name="interest" type="radio" className="check" value="Other"/>Other
                        </p>
                    </div>
                </fieldset>
                <fieldset>
                    <label htmlFor="enquiry">Enquiry:</label>
                    <textarea name="enquiry" id="enquiry" tabIndex="4" rows="3" cols="15"></textarea>
                </fieldset>
                <fieldset>
                    <input type="submit" id="contactsend" name="contactsend" value=""/>
                </fieldset>
            </form>

        );
      }
}
function OnlineCounsel(){
    return (
        <div className="content_middle_inner">
            <div className="content_middle_in">
                <div className="title">
                    <h3>Online counseling</h3>
                </div>
                <div><OnlineCounselForm/></div>
                <div className="contact">
                    <p>SupremeCare offers counseling services on a number of topics. Please completely fill out the form by selecting your area of interest, and we will reply as soon as po ssible.</p>
                    <p>*denotes required field.  Please completely fill out the form so we may better contact you.</p>
                    <Image src={comp} alt=""/>
                </div>
            </div>
        </div>    )
}


export default OnlineCounsel;