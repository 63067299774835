import { Image } from "react-bootstrap";

import custom_9b9d6 from '../../assets/images/custom_images/produse/9b9d6.jpg'
import custom_ea6 from '../../assets/images/custom_images/produse/ea6.jpg'
import custom_50a99fb4 from '../../assets/images/custom_images/produse/50a99fb4.jpg'
import custom_7a71c89 from '../../assets/images/custom_images/produse/7a71c89.jpg'


function Products(){
    return (
        <div className="content_middle_inner">
	        <div className="content_middle_in">
		        <div className="title"><h3>Products</h3></div>
                <div className="prodServContent"></div>
                <div className="box_product">
                    <p className="prodTitle">*All pads contain 100 sheets.*</p>
				        <a href="http://www.optimasolutions.info">Contact us for detailed product information</a>
			    </div>
			    <div className="clear"></div>
                <div className="box_product">
                    <p className="prodTitle">Other SupremeCare Products:</p>
				    <div className="picture"><Image src={custom_9b9d6} alt=""/></div>
                    <ul>
                        <li>OPTIMASOLUTIONS DINING-MENU MANAGEMENT SOFTWARE</li>
                        <li>Dietary Policy &amp; Procedure Manuals</li>
                        <li>Dietary In-Service Manual</li>
                        <li>Seasonal Menus</li>
                        <li>Recipe Manual</li>
                        <li>Dietary Budget Control Manual</li>
                        <li>HACCP Control Manual</li>
                        <li>LGD Diet Manual</li>
                        <li>Nutrition Assessment Form Pad*</li>
                        <li>Dietary Flow Sheet Pad*</li>
                        <li>RD Progress Notepad Pad*</li>
                        <li>CDM Progress Pad*</li>
                        <li>Weekly PPD Cost Sheet Pad*</li>
                        <li>Sanitation Checklist Pad*</li>
                        <li>Freezer Temperature Log Pad*</li>
                        <li>Refrigerator Temperature Log Pad*</li>
                        <li>Dish Machine Temp./Chem. Log Pad*</li>
                        <li>Compartment Sink Temp./Chem. Log Pad*</li>
                    </ul>
			    </div>
			    <div className="clear"></div>
                <div className="box_product">
                    <p className="prodTitle">Clinical System</p>
				    <div className="picture"><Image src={custom_ea6} alt=""/></div>
                    <ul>
                        <li>Supplement Usage Manager</li>
                        <li>Weight Tracker</li>
                        <li>Historical assessments for nutrition management, IBW, Usual Body Weight &amp; Body Mass Index Calculator</li>
                        <li>Supplement Master</li>
                        <li>Diet Master</li>
                    </ul>
			    </div>
			    <div className="clear"></div>
                <div className="box_product">
                    <p className="prodTitle">Management System</p>
				    <div className="picture"><Image src={custom_50a99fb4} alt="" /></div>
                    <ul>
                        <li>User friendly roster on screen instantly</li>
                        <li>Instantly print any roster with numerous sort options</li>
                        <li>Tray tickets and select menus in any format</li>
                        <li>Color coding strips and diet icons</li>
                        <li>Resident pictures printed on every tray ticket/label</li>
                        <li>Adaptive equipment pictures printed on the tray ticket</li>
                        <li>Picture loading and assigning program</li>
                        <li>Bar coded tray tickets and labels</li>
                        <li>Dining location manager</li>
                        <li>Policies and procedures, in-services and diet handouts</li>
                        <li>Spend down sheets, cost reports, and meal counting</li>
                    </ul>
			    </div>
			    <div className="clear"></div>
                <div className="box_product">
                    <p className="prodTitle">Menu System</p>
				    <div className="picture"><Image src={custom_7a71c89} alt="" /></div>
                    <ul>
                        <li>Seasonal Menus</li>
                        <li>Automated spreading of diets</li>
                        <li>Complete menu item lists included</li>
                        <li>Nutritional analysis of menu items, recipes, and inventory</li>
                        <li>1700+ Healthcare quantity recipes</li>
                        <li>Productions sheets for your cooks to use</li>
                        <li>Week at a glance, spread sheets, and display menus</li>
                        <li>Inventory &amp; Purchasing control function</li>
                    </ul>
			    </div>
			    <div className="clear"></div>	
            </div>
        </div>    
    )
}

export default Products;