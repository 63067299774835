import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Services from './components/Services/Services';
import Products from './components/Products/Products';
import AboutUs from './components/AboutUs/AboutUs';
import Contact from './components/Contact/Contact';
import OnlineCounsel from './components/OnlineCounsel/OnlineCounsel';
import Footer from './components/Footer/Footer';
import PageNotFound from "./components/PageNotFound/PageNotFound";

import './App.css';
import './assets/css/all.css';
import './assets/css/sIFR-print.css';
import './assets/css/sIFR-screen.css';


function App() {
  return (
    <div className="App">
    <Router>
      <Header/>
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About-us" element={<AboutUs />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Products" element={<Products />} />
          {/*<Route path="/Online-counseling" element={<OnlineCounsel />} />*/}
          <Route path="/Contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
    </div>
  );
}

export default App;
