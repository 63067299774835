import { Image } from "react-bootstrap";

import custom_8d7 from '../../assets/images/custom_images/servicii/8d7.jpg'
import custom_565 from '../../assets/images/custom_images/servicii/565.jpg'
import custom_96935e6 from '../../assets/images/custom_images/servicii/96935e6.jpg'
import custom_ef875663 from '../../assets/images/custom_images/servicii/ef875663.jpg'
import custom_2d5c4 from '../../assets/images/custom_images/servicii/2d5c4.jpg'
import custom_2e115b6a from '../../assets/images/custom_images/servicii/2e115b6a.jpg'

function Services(){
    return (
        <div className="content_middle_inner">
	        <div className="content_middle_in">
		        <div className="title"><h3>Services</h3></div>
                <div className="prodServContent">
                    <p>SupremeCare Corporation prides itself on being the largest Dietary consulting firm on the East Coast, as well as a full service nutrition support company.  The following services are what make SupremeCare the choice of many in our industry:</p>
                </div>
                <div className="box_product">
                    <p className="prodTitle">Dietary Management Services</p>
				    <div className="picture">
                        <Image src={custom_8d7} alt=""/>
                    </div>
                    <ul>
                        <li>Registered Dietician Consultants</li>
                        <li>Nutritional QA Consultants</li>
                        <li>Certified Dietary Manager Consultants</li>
                        <li>Management Programs</li>
                        <li>Policy &amp; Procedures, In-service, HAACP, and Joint Commission Manuals</li>
                        <li>Seasonal Menus/Recipe Systems</li>
                        <li>CQI Forms</li>
                    </ul>
			    </div>
			    <div className="clear"></div>
                <div className="box_product">
                    <p className="prodTitle">Group Purchasing</p>
				    <div className="picture">
                        <Image src={custom_565} alt="" />
                    </div>
                    <ul>
                        <li>Food (~$4.85 raw food ppd)</li>
                        <li>Supplements (reduce by 50%)</li>
                        <li>Milk</li>
                        <li>Cleaning Supplies (~.05-.10 ppd)</li>
                        <li>Leases</li>
                        <li>Medical Supplies</li>
                        <li>Pharmaceuticals</li>
                    </ul>
			    </div>
			    <div className="clear"></div>
                <div className="box_product">
                    <p className="prodTitle">Administrative</p>
				    <div className="picture">
                        <Image src={custom_96935e6} alt=""/>
                    </div>
                    <ul>
                        <li>Budget Review</li>
                        <li>P &amp; L Analysis</li>
                        <li>Payroll Analysis</li>
                        <li>Interim Administrators</li>
                    </ul>
			</div>
			<div className="clear"></div>
            <div className="box_product">
                <p className="prodTitle">Pre-Surveys</p>
				<div className="picture">
                    <Image src={custom_ef875663} alt=""/>
                    </div>
                    <ul>
                        <li>Licensed Administrator</li>
                        <li>Registered Dietician</li>
                        <li>Registered Nurse</li>
                        <li>Registered Pharmacist</li>
                        <li>Environmentalist</li>
                    </ul>
			    </div>
			    <div className="clear"></div>
                <div className="box_product">
                    <p className="prodTitle">LTC Consulting/Placement Service</p>
				    <div className="picture">
                        <Image src={custom_2d5c4} alt=""/>
                    </div>
                    <ul>
                        <li>Licensed Nursing Home Administrators</li>
                        <li>Provides Client with Information on LTC placement for Loved Ones (Home Care, Assisted Living, Nursing Home Care)</li>
                        <li>Nursing Home Research (Necessary Tour Questions, survey History, and Recommendations)</li>
                        <li>Locate healthcare facilities</li>
                        <li>Assess healthcare facilities to report quality and make recommendations</li>
                        <li>Assist in placement</li>
                        <li>On-Site Facility Visits with Client, etc.</li>
                    </ul>
			    </div>
			    <div className="clear"></div>
                <div className="box_product">
                    <p className="prodTitle">Expert Witness Services for LTC Litigation</p>
				    <div className="picture">
                        <Image src={custom_2e115b6a} alt="" />
                    </div>
                    <ul>
                        <li>Provide Client with Expert Advice in all Areas of LTC Services and Regulations from our Multidisciplinary Team</li>
                    </ul>
			    </div>
			    <div className="clear"></div>		
                    <p>For the best in positive drug therapy, we recommend the services of MedicationXpert, LLC.  Click <a href="http://www.medicationxpert.com" target="_blank">here</a> to visit their website.</p>
	        </div>
        </div>    
    )
}

export default Services;