import { Link } from "react-router-dom";

function Footer() {
  return (
  <div className="footer">
    <div className="footer_in">
      <p>
      <nav>
        <Link to="/">Home</Link> |{" "}
        <Link to="About-us">About Us</Link> |{" "}
        <Link to="Services">Services | </Link> |{" "}
        <Link to="Products">Products</Link> |{" "}
        {/*<Link to="Online-counseling">Online Counseling</Link> |{" "}*/}
        <Link to="Contact">Contact Us</Link>
      </nav>
      </p>
      <p>Copyright &copy; 2023, SupremeCare Corporation. All Rights Reserved.</p>
    </div>
  </div>    
  );
}

export default Footer;