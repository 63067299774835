import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import header from '../../assets/images/header.jpg'
import men_top_1 from '../../assets/images/men_top_1.jpg'
import men_top_2 from '../../assets/images/men_top_2.jpg'
import men_top_3 from '../../assets/images/men_top_3.jpg'
import men_top_4 from '../../assets/images/men_top_4.jpg'
import men_top_5 from '../../assets/images/men_top_5.jpg'
import men_top_6 from '../../assets/images/men_top_6.jpg'
import men_top_1_over from '../../assets/images/men_top_1_over.jpg'
import men_top_2_over from '../../assets/images/men_top_2_over.jpg'
import men_top_3_over from '../../assets/images/men_top_3_over.jpg'
import men_top_4_over from '../../assets/images/men_top_4_over.jpg'
import men_top_5_over from '../../assets/images/men_top_5_over.jpg'
import men_top_6_over from '../../assets/images/men_top_6_over.jpg'

function Header() {
  return (
    <div className="header">
        <div className="menu_header">
            <nav>
            <Link to="/"><Image src={men_top_1} 
                onMouseOver={e => e.currentTarget.src = men_top_1_over} 
                onMouseOut={e => e.currentTarget.src = men_top_1}/>
            </Link>
            <Link to="About-us" ><Image src={men_top_2}
                onMouseOver={e => e.currentTarget.src = men_top_2_over} 
                onMouseOut={e => e.currentTarget.src = men_top_2}/>
            </Link>
            <Link to="Services" ><Image src={men_top_3}
                onMouseOver={e => e.currentTarget.src = men_top_3_over} 
                onMouseOut={e => e.currentTarget.src = men_top_3}/>
            </Link>
            <Link to="Products" ><Image src={men_top_4}
                onMouseOver={e => e.currentTarget.src = men_top_4_over} 
                onMouseOut={e => e.currentTarget.src = men_top_4}/>
            </Link>
            <Link  to="#" ><Image src={men_top_5} 
                /*onMouseOver={e => e.currentTarget.src = men_top_5_over} 
                onMouseOut={e => e.currentTarget.src = men_top_5}*//>
            </Link>
            <Link to="Contact" ><Image src={men_top_6}
                onMouseOver={e => e.currentTarget.src = men_top_6_over}
                onMouseOut={e => e.currentTarget.src = men_top_6}/>
            </Link>
            </nav>
        </div>
        <Image alt="" src={header} className="pik_header" />
    </div>
  );
}

export default Header;