import { Link } from "react-router-dom";

function Contact(){
    return (
        <div className="content_middle_inner">
    	    <div className="content_middle_in">
	        	<div className="title"><h3>Contact us</h3></div>
		        <div className="contact_div">
                    <ul>
                        <li>
                            <h2>E. Camp Neel, Ph.D., NHA</h2>
                        </li>
                        <li>President/CEO</li>
                        <li>SupremeCare Corporation</li>
                        <li>322 South 6th Street</li>
                        <li>Griffin, Georgia 30224</li>
                        <li>Office: 770-233-1159</li>
                        <li>Fax: 770-233-5138</li>
                    </ul>
                    <ul>
                        <li>
                            <h2>Tim Rife</h2>
                        </li>
                        <li>VP of Operations</li>
                        <li>SupremeCare Corporation</li>
                        <li>322 South 6th Street</li>
                        <li>Griffin, Georgia 30224</li>
                        <li>Office: 770-233-1159</li>
                        <li>Fax: 770-233-5138</li>
                    </ul>
                    <ul>
                        <li>
                            <h2>Steven Thomas</h2>
                        </li>
                        <li>Director of IT</li>
                        <li>322 South 6th Street</li>
                        <li>Griffin, Georgia 30224</li>
                        <li>Office: 770-233-1159</li>
                        <li>Fax: 770-233-5138</li>
                    </ul>
                    <ul>
                        <li>
                            <h2>Dana Bagwell</h2>
                        </li>
                        <li>Comptroller</li>
                        <li>322 South 6th Street</li>
                        <li>Griffin, Georgia 30224</li>
                        <li>Office: 770-233-1159</li>
                        <li>Fax: 770-233-5138</li>
                    </ul>
                    <p>To arrange a meeting or inquire about products and services please you can contact us by: (<Link to="../Online-counseling">for health related advice, try our online counseling service</Link>)</p>
                </div>
	        </div>
        </div>    
    )
}



export default Contact;