
function PageNotFound() {
  return (
    <div class="content_middle">
      <div class="content_middle_in">
        Page Not Found
      <p>Copyright &copy; 2008, SupremeCare Corporation. All Rights Reserved.</p>
    </div>
  </div>    
  );
}

export default PageNotFound;